import Api from "@/core/apis";

const ShoppableService = {
  getConfig(site) {
    return Api().$get("shoppable/config/" + site);
  },
  updateConfig(site, data) {
    return Api().$put("shoppable/config/" + site, data);
  },
  getAllLibrary() {
    return Api().$get("shoppable/library");
  },
  addLibrary(data) {
    return Api().$post("shoppable/library", data);
  },
  updateLibrary(data) {
    return Api().$post("shoppable/library/update", data);
  },
  deleteLibrary(id) {
    return Api().$delete("shoppable/library/" + id);
  },
  addImage(data) {
    return Api().$post("shoppable/image", data);
  },
  updateImage(data) {
    return Api().$post("shoppable/image/update", data);
  },
  deleteImage(id) {
    return Api().$delete("shoppable/image/" + id);
  }
};

export default ShoppableService;
