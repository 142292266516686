<template>
  <v-container class="pa-8 mb-12" fluid>
    <div class="d-flex">
      <v-card class="pa-4 mr-4 overflow-y-auto" flat style="max-width: 280px;">
        <div>
          <div class="font-weight-bold text-h6 mb-1">Website</div>

          <tp-select
            v-model="site"
            :items="ARRAY_LIST_MODEL_SITES"
            item-text="text"
            item-value="id"
            placeholder="Chọn website"
            validate="required"
          ></tp-select>
        </div>

        <v-divider class="mb-4"></v-divider>

        <div>
          <div class="font-weight-bold text-h6">Thiết lập chung</div>

          <div class="mt-1">
            <div class="font-weight-bold mb-1">Tiêu đề</div>

            <tp-text-field
              v-model="config.title"
              placeholder="Nhập tiêu đề"
              validate="required"
            ></tp-text-field>
          </div>

          <div>
            <div class="font-weight-bold mb-1">Mô tả</div>

            <tp-text-field
              v-model="config.description"
              placeholder="Nhập mô tả"
              validate="required"
            ></tp-text-field>
          </div>

          <div>
            <div class="font-weight-bold mb-1">Loại bố cục</div>

            <tp-select
              v-model="config.layout"
              :items="[{ label: 'Masonry', value: 'masonry' }]"
              item-text="label"
              item-value="value"
              placeholder="Chọn loại bố cục"
              validate="required"
            ></tp-select>
          </div>
        </div>

        <div>
          <div class="font-weight-bold text-h6">Thông tin SEO</div>

          <div class="mt-1">
            <div class="font-weight-bold mb-1">Tiêu đề trang</div>

            <tp-text-field
              v-model="config.seo.title"
              placeholder="Nhập tiêu đề trang"
              validate="required"
            ></tp-text-field>
          </div>

          <div>
            <div class="font-weight-bold mb-1">Mô tả</div>

            <tp-text-field
              v-model="config.seo.description"
              placeholder="Nhập mô tả trang"
              validate="required"
            ></tp-text-field>
          </div>
        </div>

        <div class="mt-2">
          <v-btn :loading="isLoading" color="primary" @click="mutate"
            >Lưu
          </v-btn>
        </div>
      </v-card>

      <router-view></router-view>
    </div>
  </v-container>
</template>

<script>
import { defineComponent, getCurrentInstance, ref, watch } from "vue";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";
import { useMutation } from "@thinkvn/composables/lib/useMutation";
import { ARRAY_LIST_MODEL_SITES, LIST_MODEL_SITES } from "@/core/constant";
import { showToastActionAlert } from "@/core/composables";
import ShoppableService from "./services";

export default defineComponent({
  setup() {
    const context = getCurrentInstance().proxy;
    const site = ref(LIST_MODEL_SITES.THINKPRO.id);
    const { data: config, fetch } = useFetchData(
      () => ShoppableService.getConfig(site.value),
      {
        initialData: {
          title: "",
          description: "",
          layout: "masonry",
          seo: {
            title: "",
            description: ""
          }
        },
        transform: response => response.data
      }
    );

    watch(site, fetch, { immediate: true });

    const { mutate, isLoading } = useMutation(
      () => ShoppableService.updateConfig(site.value, config.value),
      {
        onError(e) {
          showToastActionAlert(context, {
            message: e.message || e.response.data.message
          });
        },
        onSuccess() {
          showToastActionAlert(context, {
            message: "Lưu cài đặt shoppable thành công"
          });
        }
      }
    );

    return {
      site,
      config,
      mutate,
      isLoading
    };
  },

  data() {
    return {
      ARRAY_LIST_MODEL_SITES: ARRAY_LIST_MODEL_SITES
    };
  }
});
</script>
